<template>
  <div class="auth">
    <div class="auth-card">
      <div class="auth-card__logo">
        <img src="@/assets/img/logoBSL-black.svg" alt="BSL">
      </div>
      <h1 class="auth-card__title mt-6">Авторизация</h1>

      <v-form ref="form" v-model="isValid" lazy-validation @submit.prevent="submit" class="mt-6">
        <v-text-field
          v-model="form.email"
          :rules="emailRules"
          label="E-mail"
          class="rounded-lg"
          required
          outlined
          persistent-placeholder
          rounded />

        <v-text-field
          v-model="form.password"
          :rules="passwordRules"
          label="Пароль"
          class="rounded-lg"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          outlined
          persistent-placeholder
          rounded />

        <div v-if="errors.length" class="auth-card__subtitle error--text mb-2">
          Ошибка авторизации
        </div>

        <v-btn color="black" dark x-large class="modal__btn" type="submit">
          Войти
        </v-btn>

        <div class="text-center mt-6">
          <router-link :to="{name: 'ForgotPassword'}">
            Забыли пароль?
          </router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Login',
  data: () => ({
    isValid: true,
    showPassword: false,
    form: {
      email: '',
      password: '',
    },
  }),

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      errors: 'auth/errors',
    }),
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async submit() {
      if (this.loading) return;
      await this.$refs.form.validate();
      if (!this.isValid) return;
      this.login(this.form).then(() => {
        this.$router.push({ name: 'MainPage' });
      });
    },
  },
};
</script>
